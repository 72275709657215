<template>
  <div class="header">
    <div>
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-h-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-h-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-h.png" alt="">
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="door-title">
        <div class="title">{{$t('m.business.tbs_2')}}</div>
        <div class="title-bg">
          <div class="icon-door"></div>
        </div>
      </div>
      <div class="door-img">
        <img :src="info.coverUrlName" alt="">
      </div>
      <div style="font-size: 0.27rem;font-weight: 300;color: #27201B;margin-top:0.56rem;">
        {{info.infoLabel}}
      </div>
      <div style="width:0.78rem;height:0.05rem;background:#F5A61D;margin-top:0.36rem;"></div>
      <div style="font-size: 0.48rem;font-weight:bold;color: #27201B;margin-top:0.38rem;">{{info.upName}}</div>
      <div style="font-size: 0.37rem;font-weight:300;color: #27201B;margin-top:0.36rem;margin-bottom:1rem;" v-html="info.infoContent"></div>
    </div>
  </div>
</template>

<script>
import { information_info } from '@/api/face'
export default {
  name: 'Header',
  data() {
    return {
      id: null,
      info: {}
    }
  },
  created() {
    this.id=this.$route.query.id
    this.queryInfo()
  },
  methods: {
    queryInfo() {
      information_info({id: this.id}).then(res => {
        if (res.code === 0) {
          this.info = res.data
        }
      })
    },
    jumpToHome() {
      this.$router.push('/')
    },
    jumpToHome1() {
      this.$router.push('/home2')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-size: 100% 100%;
}
.header-img {
  padding: 0.3rem 0.4rem 0 0.4rem;
  display: flex;
  justify-content: space-between;
}
.header-text {
  width: 6.6rem;
  position: absolute;
  margin-left: -3.2rem;
  top: 38%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 6px;
}
.content-box{
  padding: 1.2rem 0.36rem 0.9rem 0.36rem;
  box-sizing: border-box;
  background: #F5F5F5;
  min-height: 100vh;
}
.door-title{
  position: relative;
}
.title{
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201B;
  z-index: 10;
}
.title-bg{
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4rem;
  height: 0.34rem;
  background:rgba(255, 171, 10, 1);;
}
.icon-door{
  position: absolute;
  right: 0;
  top: -0.3rem;
  width: 0.56rem;
  height: 0.59rem;
  background: url('../../static/images/door.png');
  background-size: 100% 100%;
}
.door-img{
  width: 9.2rem;
  height: 11rem;
  margin-top: 0.8rem;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>